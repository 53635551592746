import React from 'react';
import Layout from "../components/layout";
// import Sidebar from "../components/sidebar";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { FaGlobeEurope, FaUsers, FaLinkedinIn, FaYoutubeSquare, FaTwitterSquare, FaXingSquare, FaInstagramSquare } from 'react-icons/fa';
import { colorsMapping } from "../libs/colorsMapping";

export default function singleCompanyPage({ data: { company } }) {

  let logo = company.logo;
  // if (company.logo) {
  //   logo = JSON.parse(company.logo);
  // }

  return (
    <Layout>
      <main className="flex-1 w-full max-w-4xl px-4 py-4 mx-auto md:px-8 md:py-8">
        <SEO
          keywords={[
            `Tech companies in germany`,
            `Work in germany`,
            `information technology germany`,
          ]}
          title="Explore and find Tech companies in Germany"
        />
        <section className="flex-1 w-full full bg-gray-300 shadow-xl antialiased">
          <div className="p-4">
            <h2 className="mb-0 text-center text-gray-800 font-semibold tracking-wider uppercase">
              {company.name}
            </h2>

            <div className="flex items-center justify-around">
          <span className="text-center text-xs text-gray-600 mt-2 ">
            {company.address}{" "}
          </span>
            </div>

            <div className="p-8 mt-2 ">
              {logo ? (
                <Img
                  imgStyle={{ objectFit: "contain" }}
                  className="object-contain rounded-lg m-auto h-16 w-16"
                  fluid={logo.childImageSharp.fluid}
                />
              ) : (
                ""
              )}
            </div>

            <div className="flex items-center justify-around w-full mb-4 border-b-4 pb-4">
              {company.twitter ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company.twitter}
                >
                  <FaTwitterSquare className="h-8 w-4 fill-current " style={{ color: "#1DA1F2" }} />
                </a>
              ) : (
                ""
              )}
              {company.xing ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company.xing}
                >
                  <FaXingSquare className="h-8 w-4 fill-current" style={{ color: "#126567" }} />
                </a>
              ) : (
                ""
              )}
              {company.linkedin ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company.linkedin}
                >
                  <FaLinkedinIn className="h-8 w-4 fill-current" style={{ color: "#2867B2" }} />
                </a>
              ) : (
                ""
              )}
              {company.instgram ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company.instgram}
                >
                  <FaInstagramSquare className="h-8 w-4 fill-current" style={{ color: "#833AB4" }} />
                </a>
              ) : (
                ""
              )}
              {company.youtube ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company.youtube}
                >
                  <FaYoutubeSquare className="h-8 w-4 fill-current" style={{ color: "#FF0000" }} />
                </a>
              ) : (
                ""
              )}

            </div>


            <div className="flex flex-col items-start">
              {company.industry ? (
                <div className="flex items-center justify-around">
              <span className="text-md text-gray-900 mt-2 font-semibold mx-2">
                Industry{" "}
              </span>
                  {/* <span className={"text-md text-center text-gray-700 mt-2" colorsMapping[colorsMapping.indexOf(company.industry) + 1]}> */}
                  <span
                    style={{
                      backgroundColor: colorsMapping[colorsMapping.indexOf(company.industry) + 1] ? '#1982C4' : 'green',
                      borderRadius: "3px",
                      padding: "1px 4px",
                    }}
                    className={`text-xs text-center text-white mt-2 ${
                      colorsMapping[
                      colorsMapping.indexOf(company.industry) + 1
                        ]
                    }`}
                  >
                {company.industry}{" "}
              </span>
                </div>
              ) : (
                ""
              )}
              {company.careersPage ? (
                <div className="flex items-center justify-around">
              <span className="text-md text-gray-900 mt-2 font-semibold mx-2">
                Careers Page{" "}
              </span>
                  {/*<span className="text-md text-center text-gray-700 mt-2 ">{company.careersPage} </span>*/}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={company.careersPage}
                  >
                    <svg
                      className="h-4 w-4 mt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </a>
                </div>
              ) : (
                ""
              )}
              {company.headquarters ? (
                <div className="flex items-center justify-around">
              <span className="text-md text-gray-900 mt-2 font-semibold mx-2">
                Headquarters{" "}
              </span>
                  <span className="text-xs text-gray-700 mt-2">
                {company.headquarters}{" "}
              </span>
                </div>
              ) : (
                ""
              )}

              {company.website ? (
                <div className="flex items-center justify-around mt-2">
              <span className="text-md text-gray-900 font-semibold mx-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={company.website}
                >
                <FaGlobeEurope />
              </a>
              </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={company.website}
                    className="text-sm no-underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    {company.website}
                  </a>

                </div>
              ) : (
                ""
              )}
              {company.size ? (
                <div className="flex items-center justify-around mt-2">
              <span className="text-md text-gray-900 font-semibold mx-2">
              <FaUsers className="w-6 h-6" />{" "}
              </span>
                  <span className="text-sm font-medium text-gray-700 mx-2">
                {company.size}
              </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}


export const query = graphql`
    query($slug: String!){
        company: strapiCompanies(slug: {eq: $slug}) {
            name
            website
            id
            city
            address
            careersPage
            Linkedin
            Xing
            youtube
            twitter
            instgram
            size
            longitude
            headquarters
            latitude
            industry
            slug
            logo {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`;